/* eslint-disable react/display-name */
import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import {
  green,
  red,
  black,
  white,
  currencyFormat,
  formatNumberWithPercent,
  formatNumber,
} from '../../utils';

const getColor2 = (value, type, theme) => {
  if (
    type === 'Dollars-Up' ||
    type === 'Dollars-Down' ||
    type === 'Inst Amounts' ||
    type === 'Price Diff' ||
    type === '% SHRS Change' ||
    type === 'Volume Diff' ||
    type === 'Price Diff %' ||
    type === 'Volume Diff %' ||
    type === 'Approx Inv value' ||
    type === 'EPS Difference' ||
    type === '% Difference' ||
    type === 'BottomLine ($Up - $Down)'
  ) {
    return value > 0
      ? green
      : value < 0
      ? red
      : theme === 'dark'
      ? white
      : black;
  } else return theme === 'dark' ? white : black;
};

const getValue = (type, value) => {
  if (
    type === 'Price Diff %' ||
    type === 'Volume Diff %' ||
    type === '% Difference' ||
    type === '% SHRS Change'
  ) {
    return formatNumberWithPercent(value);
  }
  if (type === 'Volume Diff') {
    return formatNumber(value);
  }
  if (
    type === 'Inst Amounts' ||
    type === 'Approx Inv value' ||
    type === 'Revenues' ||
    type === 'Total Revenue' ||
    type === 'Net Income Loss' ||
    type === 'Net Income' ||
    type === 'Operating Expenses' ||
    type === 'Gross Income' ||
    type === 'Gross Profit'
  ) {
    return currencyFormat(value, false, false, false, false, true);
  }
  return currencyFormat(value);
};

export const formatDataRow = (tableRow, theme) => {
  let formattedData = [];
  // eslint-disable-next-line array-callback-return
  tableRow.forEach((row) => {
    if (
      row.type === 'Dollars-Down' ||
      row.type === 'Dollars-Up' ||
      row.type === 'Average' ||
      row.type === '% SHRS Change' ||
      row.type === 'Inst Amounts' ||
      row.type === 'BottomLine ($Up - $Down)' ||
      row.type === 'Price' ||
      row.type === 'Price Diff' ||
      row.type === 'Volume Diff' ||
      row.type === 'Price Diff %' ||
      row.type === 'Volume Diff %' ||
      row.type === 'EPS' ||
      row.type === 'Revenues' ||
      row.type === 'Net Income Loss' ||
      row.type === 'Operating Expenses' ||
      row.type === 'Net Income' ||
      row.type === 'Gross Profit' ||
      row.type === 'EPS' ||
      row.type === 'Diluted EPS' ||
      row.type === 'EPS Actual' ||
      row.type === 'EPS Estimate' ||
      row.type === 'EPS Difference' ||
      row.type === '% Difference' ||
      row.type === 'Gross Income' ||
      row.type === 'Net Income' ||
      row.type === 'Total Revenue' ||
      row.type === 'Approx Inv value' ||
      row.type === 'Avg Price'
    ) {
      let obj = {};
      const tooltipText = {
        'Approx Inv value': 'last 2 Qtrs Shares Diff * (last Qtr Avg Price)',
        'Net Income': 'Gross Income - Operating Expenses - Interest - Taxes',
        'Gross Income': 'Total Revenue - Cost of Goods Sold (COGS)',
        EPS: `(Net Income - Preferred Dividends) / Average Outstanding Shares`,
        'Total Revenue': 'Price per Unit * Number of Units Sold',
      };
      for (const [key, value] of Object.entries(row)) {
        obj[key] =
          key === 'type' || key === 'lastSale' || key === 'status' ? (
            key === 'type' && value === 'Approx Inv value' ? (
              <>
                {`${value}  `}
                <Tooltip
                  placement="top"
                  title={
                    value === 'EPS' ? (
                      <>
                        <b>Earnings Per Share</b>
                        <br />
                        {tooltipText[value]}
                      </>
                    ) : (
                      tooltipText[value]
                    )
                  }
                  color={green}
                  overlayStyle={{
                    color: black,
                    width: 200,
                  }}
                >
                  <span style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                    <InfoCircleOutlined
                      style={{ color: green, fontSize: 14, paddingLeft: 10 }}
                    />
                  </span>
                </Tooltip>
              </>
            ) : (
              value
            )
          ) : value ? (
            <span
              style={{
                color: getColor2(value, row.type, theme),
                fontSize: 12,
              }}
            >
              {`${getValue(row.type, value)}`}
            </span>
          ) : (
            '-'
          );
      }
      formattedData.push(obj);
    } else {
      formattedData.push(row);
    }
  });
  return formattedData;
};

const formattedString = (data) => {
  const str = `UP-MARKET: $${data[0][0]},$${data[0][1]} ; DOWN-MARKET: $${data[1][0]},$${data[1][1]} *As always please do your due diligence.`;

  let values = str.split('*');
  return (
    <>
      <p>{values[0]} </p>
      <p>{`*${values[1]}`} </p>
    </>
  );
};

export const formatForecastedData = (tableRow, note) => {
  let formattedData = [];
  // eslint-disable-next-line array-callback-return
  tableRow.map((row) => {
    let obj = {};
    for (const [key, value] of Object.entries(row)) {
      obj[key] =
        key === 'conclusion' ? (
          <>
            {`${value}  `}
            <Tooltip
              placement="top"
              title={note}
              color={green}
              overlayStyle={{
                color: black,
                width: 200,
              }}
            >
              <span style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                <InfoCircleOutlined
                  style={{ color: green, fontSize: 14, paddingLeft: 10 }}
                />
              </span>
            </Tooltip>
          </>
        ) : key === 'forecastedClosingPrices' ? (
          formattedString(value)
        ) : (
          value
        );
    }
    formattedData.push(obj);
  });
  return formattedData;
};
